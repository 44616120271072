@font-face {
  font-family: "Futura Cyrillic Book";
  src: url("FuturaCyrillicBook.eot");
  src: url("FuturaCyrillicBook.eot?#iefix") format("embedded-opentype"),
    url("FuturaCyrillicBook.woff2") format("woff2"),
    url("FuturaCyrillicBook.woff") format("woff"),
    url("FuturaCyrillicBook.ttf") format("truetype"),
    url("FuturaCyrillicBook.svg#FuturaCyrillicBook") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura Cyrillic Demi";
  src: url("FuturaCyrillic-Demi.eot");
  src: url("FuturaCyrillic-Demi.eot?#iefix") format("embedded-opentype"),
    url("FuturaCyrillic-Demi.woff2") format("woff2"),
    url("FuturaCyrillic-Demi.woff") format("woff"),
    url("FuturaCyrillic-Demi.ttf") format("truetype"),
    url("FuturaCyrillic-Demi.svg#FuturaCyrillic-Demi") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura Cyrillic";
  src: url("FuturaCyrillic-Bold.eot");
  src: url("FuturaCyrillic-Bold.eot?#iefix") format("embedded-opentype"),
    url("FuturaCyrillic-Bold.woff2") format("woff2"),
    url("FuturaCyrillic-Bold.woff") format("woff"),
    url("FuturaCyrillic-Bold.ttf") format("truetype"),
    url("FuturaCyrillic-Bold.svg#FuturaCyrillic-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura Cyrillic Extra";
  src: url("FuturaCyrillic-ExtraBold.eot");
  src: url("FuturaCyrillic-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("FuturaCyrillic-ExtraBold.woff2") format("woff2"),
    url("FuturaCyrillic-ExtraBold.woff") format("woff"),
    url("FuturaCyrillic-ExtraBold.ttf") format("truetype"),
    url("FuturaCyrillic-ExtraBold.svg#FuturaCyrillic-ExtraBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura Cyrillic";
  src: url("FuturaCyrillicMedium.eot");
  src: url("FuturaCyrillicMedium.eot?#iefix") format("embedded-opentype"),
    url("FuturaCyrillicMedium.woff2") format("woff2"),
    url("FuturaCyrillicMedium.woff") format("woff"),
    url("FuturaCyrillicMedium.ttf") format("truetype"),
    url("FuturaCyrillicMedium.svg#FuturaCyrillicMedium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura Cyrillic";
  src: url("FuturaCyrillicLight.eot");
  src: url("FuturaCyrillicLight.eot?#iefix") format("embedded-opentype"),
    url("FuturaCyrillicLight.woff2") format("woff2"),
    url("FuturaCyrillicLight.woff") format("woff"),
    url("FuturaCyrillicLight.ttf") format("truetype"),
    url("FuturaCyrillicLight.svg#FuturaCyrillicLight") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura Cyrillic";
  src: url("FuturaCyrillicHeavy.eot");
  src: url("FuturaCyrillicHeavy.eot?#iefix") format("embedded-opentype"),
    url("FuturaCyrillicHeavy.woff2") format("woff2"),
    url("FuturaCyrillicHeavy.woff") format("woff"),
    url("FuturaCyrillicHeavy.ttf") format("truetype"),
    url("FuturaCyrillicHeavy.svg#FuturaCyrillicHeavy") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Futura";
  src: url("Futura-Medium.eot");
  src: url("Futura-Medium.eot?#iefix") format("embedded-opentype"),
    url("Futura-Medium.woff2") format("woff2"),
    url("Futura-Medium.woff") format("woff"),
    url("Futura-Medium.ttf") format("truetype"),
    url("Futura-Medium.svg#Futura-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
